<template>
  <v-app :style="inlineBgImage()">
    <router-view />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PagesIndex',
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting', 'getActTheme']),
    getTheme() {
      return this.getDarkModeSetting ? 'dark' : 'light';
    },
  },
  methods: {
    inlineBgImage() {
      // eslint-disable-next-line global-require
      const bgImage = require('../assets/Lizenzserver_Backround_only.png');
      return (
        ` backgroundImage: url("${bgImage}"); ` +
        ` background-size: cover;` /* for IE9+, Safari 4.1+, Chrome 3.0+, Firefox 3.6+ */ +
        `-webkit-background-size: cover;` /* for Safari 3.0 - 4.0 , Chrome 1.0 - 3.0 */ +
        `-moz-background-size: cover;` /* optional for Firefox 3.6 */ +
        `-o-background-size: cover;` /* for Opera 9.5 */ +
        `margin: 0;` /* to remove the default white margin of body */ +
        `padding: 0;` /* to remove the default white margin of body */ +
        `overflow: hidden;`
      );
    },
  },
  created() {
    this.$vuetify.theme.themes = this.getActTheme;
    this.$vuetify.theme.dark = this.getDarkModeSetting;
  },
};
</script>

<style></style>
